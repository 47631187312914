import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Footer: React.FC = () => (
  <footer className="bg-teal-500">
    <div className="flex items-center justify-between max-w-4xl mx-auto p-4 md:p-8 text-white">
      <div>Created by Jeroen Druw&eacute;</div>
      <ul className={"flex flex-row text-2xl list-none"}>
        <li>
          <a
            href="https://github.com/Jdruwe"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
            aria-label="Github"
          >
            <FontAwesomeIcon icon={["fab", "github"]} />
          </a>
        </li>
        <li className={"mx-3"}>
          <a
            href="https://twitter.com/JeroenDruwe"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
            aria-label="Twitter"
          >
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/jeroen-druwe"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold"
            aria-label="LinkedIn"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
