import React, { useState } from "react"
import useInterval from "../hooks/intervalHook"

const topics = ["Code", "Fitness", "Friends", "Guitar", "Football", "Cycling"]

const intervalInMillis = 2000

const Interests: React.FC = () => {
  const [topic, setTopic] = useState(0)

  const isNextTopicAvailable = (newIndex: number): boolean => {
    return newIndex <= topics.length - 1
  }

  const changeTopic = () => {
    const newIndex = topic + 1
    if (isNextTopicAvailable(newIndex)) {
      setTopic(newIndex)
    } else {
      setTopic(0)
    }
  }

  useInterval(() => {
    changeTopic()
  }, intervalInMillis)

  return <span>{topics[topic]}</span>
}

export default Interests
