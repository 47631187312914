import React from "react"
import Helmet from "react-helmet"
import { Image } from "../../interfaces/prismic"

interface Props {
  author: string
  title: string
  desc: string
  image: Image
}

const Twitter: React.FC<Props> = ({ author, title, desc, image }) => {
  return (
    <>
      <Helmet>
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={image.url} />
        <meta name="twitter:image:alt" content={image.alt} />
      </Helmet>
    </>
  )
}
export default Twitter
