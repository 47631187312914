import React from "react"
import Helmet from "react-helmet"
import { Image } from "../../interfaces/prismic"

interface Props {
  url: string
  title: string
  desc: string
  type: string
  image: Image
}

const Facebook: React.FC<Props> = ({ url, type, title, desc, image }) => (
  <Helmet>
    <meta property="og:site_name" content="jeroendruwe" />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={image.url} />
    <meta property="og:image:alt" content={image.alt} />
    {image.dimensions && (
      <meta name="og:image:width" content={String(image.dimensions.width)} />
    )}
    {image.dimensions && (
      <meta name="og:image:height" content={String(image.dimensions.height)} />
    )}
  </Helmet>
)

export default Facebook
