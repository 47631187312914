import React from "react"
import Helmet from "react-helmet"
import { Article, Seo } from "../../interfaces/seo"

interface Props {
  seo: Seo
  article?: Article
}

const getSharedData = (seo: Seo) => {
  return {
    "@context": "http://schema.org",
    author: {
      "@type": "Person",
      name: seo.author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: seo.author,
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Person",
      name: seo.author,
    },
    publisher: {
      "@type": "Organization",
      name: seo.author,
      logo: {
        "@type": "ImageObject",
        url: seo.defaultImage.url,
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": seo.url,
    },
    description: seo.description,
    headline: seo.title,
  }
}

const getStructuredData = (seo: Seo) => {
  return {
    "@type": "WebPage",
    ...getSharedData(seo),
  }
}

const getStructuredArticle = (seo: Seo, article: Article) => {
  return {
    "@type": "Article",
    datePublished: article.datePublished,
    dateModified: article.dateModified,
    image: {
      "@type": "ImageObject",
      url: seo.image.url,
    },
    ...getSharedData(seo),
  }
}

const StructuredData: React.FC<Props> = ({ seo, article }) => (
  <Helmet>
    {!article && (
      <script type="application/ld+json">
        {JSON.stringify(getStructuredData(seo))}
      </script>
    )}
    {article && (
      <script type="application/ld+json">
        {JSON.stringify(getStructuredArticle(seo, article))}
      </script>
    )}
  </Helmet>
)

export default StructuredData
