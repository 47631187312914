import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "../../interfaces/prismic"
import Twitter from "./TwitterComponent"
import Facebook from "./FacebookComponent"
import StructuredData from "./StructuredDataComponent"
import { Article, Seo } from "../../interfaces/seo"

interface Props {
  title?: string
  lang?: string
  description?: string
  image?: Image
  path?: string
  article?: Article
}

const getType = (isArticle?: Article) => {
  return isArticle ? "article" : "website"
}

const getDescription = (
  siteMetaData: SiteMetaData,
  description?: string
): string => {
  return description || siteMetaData.description
}

const getAuthor = (siteMetaData: SiteMetaData): string => {
  return siteMetaData.author
}

const getTwitterHandle = (siteMetaData: SiteMetaData): string => {
  return siteMetaData.twitterHandle
}

const getUrl = (siteMetaData: SiteMetaData, path?: string): string => {
  return path ? `${siteMetaData.siteUrl}/${path}` : siteMetaData.siteUrl
}

const getTitle = (siteMetaData: SiteMetaData, title?: string): string => {
  return title ? `${title} | ${siteMetaData.title}` : siteMetaData.title
}

const getDefaultImage = (siteMetaData: SiteMetaData): Image => {
  return {
    url: `${siteMetaData.siteUrl}/${siteMetaData.banner}`,
    alt: siteMetaData.description,
  }
}

const getImage = (siteMetaData: SiteMetaData, image?: Image): Image => {
  return image ? image : getDefaultImage(siteMetaData)
}

interface SiteMetaData {
  title: string
  description: string
  author: string
  twitterHandle: string
  siteUrl: string
  banner: string
}

const SEO: React.FC<Props> = ({
  description = "",
  lang = "en",
  title,
  image,
  path,
  article,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterHandle
            siteUrl
            banner
          }
        }
      }
    `
  )

  const seo: Seo = {
    type: getType(article),
    description: getDescription(site.siteMetadata, description),
    author: getAuthor(site.siteMetadata),
    twitterHandle: getTwitterHandle(site.siteMetadata),
    url: getUrl(site.siteMetadata, path),
    title: getTitle(site.siteMetadata, title),
    defaultImage: getDefaultImage(site.siteMetadata),
    image: getImage(site.siteMetadata, image),
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={seo.title}
        meta={[
          {
            name: `description`,
            content: seo.description,
          },
          {
            property: `image`,
            content: seo.image.url,
          },
        ]}
      />
      <StructuredData article={article} seo={seo} />
      <Facebook
        url={seo.url}
        type={seo.type}
        title={seo.title}
        desc={seo.description}
        image={seo.image}
      />
      <Twitter
        title={seo.title}
        author={seo.twitterHandle}
        desc={seo.description}
        image={seo.image}
      />
    </>
  )
}

export default SEO
