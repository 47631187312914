import React from "react"

import { config, library } from "@fortawesome/fontawesome-svg-core"
import {
  faHeart,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import {
  faTwitter,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import "@fortawesome/fontawesome-svg-core/styles.css"

import "./layout.css"

import Header from "./HeaderComponent"
import Footer from "./FooterComponent"

config.autoAddCss = false

library.add(faHeart, faArrowLeft, faArrowRight, faTwitter, faGithub, faLinkedin)

interface Props {
  children: React.ReactNode
  bottomPaddingDisabled?: boolean
}

const Layout: React.FC<Props> = ({ children, bottomPaddingDisabled }) => {
  return (
    <div className="flex flex-col min-h-screen font-body bg-gray-100">
      <Header />
      <main
        className={
          "flex flex-col flex-1 max-w-4xl mx-auto p-4 md:px-8 md:py-16 w-full " +
          (bottomPaddingDisabled ? "pb-0 md:pb-0" : "")
        }
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
