import { Link } from "gatsby"
import React from "react"
import Interests from "../InterestsComponent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header: React.FC = () => (
  <header className="bg-black text-white">
    <div className="flex items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
      <p className="cursor-pointer font-medium">
        <Link to="/">Jeroen Druw&eacute;</Link>
      </p>
      <div className="text-sm font-normal">
        <FontAwesomeIcon className="text-red-500 mx-2" icon="heart" />
        <Interests />
      </div>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
